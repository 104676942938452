ag-grid-angular {
  display: block; }

ag-grid-ng2 {
  display: block; }

ag-grid {
  display: block; }

ag-grid-polymer {
  display: block; }

ag-grid-aurelia {
  display: block; }

.ag-rtl {
  direction: rtl; }

.ag-ltr {
  direction: ltr; }

.ag-select-agg-func-popup {
  position: absolute; }

.ag-body-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ag-root {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* was getting some 'should be there' scrolls, this sorts it out */
  overflow: hidden;
  /* set to relative, so absolute popups appear relative to this */
  position: relative; }

.ag-layout-normal .ag-root {
  height: 100%; }

.ag-font-style {
  cursor: default;
  /* disable user mouse selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ag-layout-for-print {
  display: block;
  white-space: nowrap; }

.ag-layout-for-print .ag-row,
.ag-layout-for-print .ag-cell {
  page-break-inside: avoid !important; }

.ag-layout-normal {
  height: 100%; }

.ag-popup-backdrop {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%; }

.ag-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; }

.ag-layout-normal .ag-header {
  left: 0;
  position: absolute;
  top: 0; }

.ag-pinned-left-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  height: 100%;
  overflow: hidden; }

.ag-pinned-right-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  float: right;
  height: 100%;
  overflow: hidden; }

.ag-header-viewport {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden; }

.ag-layout-normal .ag-header-row {
  position: absolute; }

.ag-layout-normal .ag-header-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  white-space: nowrap; }

.ag-layout-auto-height .ag-header-row {
  position: absolute; }

.ag-layout-auto-height .ag-header-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  white-space: nowrap; }

.ag-layout-for-print .ag-header-container {
  white-space: nowrap; }

.ag-header-overlay {
  display: block;
  position: absolute; }

.ag-header-cell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  position: absolute;
  vertical-align: bottom; }

.ag-floating-filter {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  position: absolute; }

.ag-floating-filter-body {
  height: 20px;
  margin-right: 25px; }

.ag-floating-filter-full-body {
  height: 20px;
  width: 100%; }

.ag-floating-filter-input {
  width: 100%; }

.ag-floating-filter-input:-moz-read-only {
  background-color: #eee; }

.ag-floating-filter-input:read-only {
  background-color: #eee; }

.ag-floating-filter-menu {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ag-dnd-ghost {
  background: #e5e5e5;
  border: 1px solid black;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: move;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
  padding: 3px;
  position: absolute;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ag-dnd-ghost-icon {
  display: inline-block;
  float: left;
  padding: 2px; }

.ag-dnd-ghost-label {
  display: inline-block; }

.ag-header-group-cell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis; }

.ag-header-group-cell-label {
  overflow: hidden;
  text-overflow: ellipsis; }

.ag-header-cell-label {
  overflow: hidden;
  text-overflow: ellipsis; }

.ag-header-cell-resize {
  cursor: col-resize;
  height: 100%;
  width: 4px; }

.ag-ltr .ag-header-cell-resize {
  float: right; }

.ag-ltr .ag-pinned-right-header .ag-header-cell-resize {
  float: left; }

.ag-rtl .ag-header-cell-resize {
  float: left; }

.ag-rtl .ag-pinned-left-header .ag-header-cell-resize {
  float: right; }

.ag-ltr .ag-header-select-all {
  float: left; }

.ag-rtl .ag-header-select-all {
  float: right; }

.ag-header-expand-icon {
  padding-left: 4px; }

.ag-header-cell-menu-button {
  float: right; }

.ag-overlay-panel {
  display: table;
  height: 100%;
  pointer-events: none;
  width: 100%; }

.ag-overlay-wrapper {
  display: table-cell;
  text-align: center;
  vertical-align: middle; }

.ag-bl-overlay {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%; }

.ag-bl-full-height {
  height: 100%;
  overflow: auto;
  position: relative; }

.ag-bl-west {
  float: left; }

.ag-bl-full-height-west {
  height: 100%; }

.ag-bl-east {
  float: right; }

.ag-bl-full-height-east {
  height: 100%; }

.ag-bl-full-height-center {
  height: 100%; }

.ag-bl-normal {
  height: 100%;
  position: relative; }

.ag-bl-normal-center-row {
  height: 100%;
  overflow: hidden; }

.ag-bl-normal-west {
  float: left;
  height: 100%; }

.ag-bl-normal-east {
  float: right;
  height: 100%; }

.ag-bl-normal-center {
  height: 100%; }

.ag-bl-dont-fill {
  position: relative; }

.ag-body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%; }

.ag-layout-normal .ag-body {
  height: 100%;
  position: absolute; }

.ag-floating-top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; }

.ag-layout-normal .ag-floating-top {
  left: 0;
  position: absolute; }

.ag-pinned-left-floating-top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-left-floating-top {
  height: 100%; }

.ag-pinned-right-floating-top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  float: right;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-right-floating-top {
  height: 100%; }

.ag-floating-top-viewport {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.ag-layout-normal .ag-floating-top-viewport {
  height: 100%; }

.ag-floating-top-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap; }

.ag-floating-bottom {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; }

.ag-layout-normal .ag-floating-bottom {
  left: 0;
  position: absolute; }

.ag-pinned-left-floating-bottom {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-left-floating-bottom {
  height: 100%; }

.ag-pinned-right-floating-bottom {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  float: right;
  overflow: hidden;
  position: relative; }

.ag-layout-normal .ag-pinned-right-floating-bottom {
  height: 100%; }

.ag-floating-bottom-viewport {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.ag-layout-normal .ag-floating-bottom-viewport {
  height: 100%; }

.ag-floating-bottom-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap; }

.ag-pinned-left-cols-viewport {
  float: left; }

.ag-pinned-left-cols-container {
  display: inline-block;
  position: relative; }

.ag-pinned-right-cols-viewport {
  float: right; }

.ag-ltr .ag-pinned-right-cols-viewport {
  overflow-x: hidden;
  overflow-y: auto; }

.ag-ltr .ag-pinned-left-cols-viewport {
  overflow: hidden; }

.ag-rtl .ag-pinned-right-cols-viewport {
  overflow: hidden; }

.ag-rtl .ag-pinned-left-cols-viewport {
  overflow-x: hidden;
  overflow-y: auto; }

.ag-pinned-right-cols-container {
  display: inline-block;
  position: relative; }

.ag-layout-normal .ag-body-viewport-wrapper {
  height: 100%; }

.ag-body-viewport {
  overflow-x: auto;
  overflow-y: auto; }

.ag-layout-normal .ag-body-viewport {
  height: 100%; }

.ag-full-width-viewport {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%; }

.ag-full-width-container {
  overflow: hidden;
  position: relative;
  width: 100%; }

.ag-floating-bottom-full-width-container {
  display: inline;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%; }

.ag-floating-top-full-width-container {
  display: inline;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%; }

.ag-full-width-row {
  overflow: hidden;
  pointer-events: all; }

.ag-layout-normal .ag-body-container {
  display: inline-block;
  margin-bottom: -2px;
  position: relative; }

.ag-layout-auto-height .ag-body-container {
  display: block;
  position: relative; }

.ag-row-animation .ag-row {
  -webkit-transition: height 0.4s, background-color 0.1s, opacity 0.2s, -webkit-transform 0.4s;
  transition: height 0.4s, background-color 0.1s, opacity 0.2s, -webkit-transform 0.4s;
  transition: transform 0.4s, height 0.4s, background-color 0.1s, opacity 0.2s;
  transition: transform 0.4s, height 0.4s, background-color 0.1s, opacity 0.2s, -webkit-transform 0.4s; }

.ag-row-no-animation .ag-row {
  -webkit-transition: background-color 0.1s;
  transition: background-color 0.1s; }

.ag-row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.ag-layout-normal .ag-row {
  position: absolute;
  white-space: nowrap;
  width: 100%; }

.ag-layout-auto-height .ag-row {
  position: relative;
  white-space: nowrap;
  width: 100%; }

.ag-layout-for-print .ag-row {
  position: relative; }

.ag-column-moving .ag-cell {
  -webkit-transition: left 0.2s;
  transition: left 0.2s; }

.ag-column-moving .ag-header-cell {
  -webkit-transition: left 0.2s;
  transition: left 0.2s; }

.ag-column-moving .ag-header-group-cell {
  -webkit-transition: left 0.2s, width 0.2s;
  transition: left 0.2s, width 0.2s; }

.ag-column-drop {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%; }

.ag-column-drop-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 50px;
  overflow: hidden; }
  .ag-column-drop-vertical .ag-column-drop-list {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 20px;
    overflow-x: auto; }
  .ag-column-drop-vertical .ag-column-drop-cell {
    display: block; }
  .ag-column-drop-vertical .ag-column-drop-empty-message {
    display: block; }
  .ag-column-drop-vertical .ag-column-drop-cell-button {
    line-height: 16px; }

.ag-ltr .ag-column-drop-vertical .ag-column-drop-cell-button {
  float: right; }

.ag-rtl .ag-column-drop-vertical .ag-column-drop-cell-button {
  float: left; }

.ag-column-drop-horizontal {
  white-space: nowrap; }
  .ag-column-drop-horizontal .ag-column-drop-cell {
    display: inline-block; }
  .ag-column-drop-horizontal .ag-column-drop-empty-message {
    display: inline-block; }
  .ag-column-drop-horizontal .ag-column-drop-list {
    height: 100%; }

.ag-cell {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ag-value-slide-out {
  margin-right: 5px;
  opacity: 1;
  -webkit-transition: opacity 3s, margin-right 3s;
  transition: opacity 3s, margin-right 3s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear; }

.ag-value-slide-out-end {
  margin-right: 10px;
  opacity: 0; }

.ag-opacity-zero {
  opacity: 0; }

.ag-cell-edit-input {
  height: 100%;
  width: 100%; }

.ag-group-cell-entire-row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.ag-footer-cell-entire-row {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.ag-large .ag-root {
  font-size: 20px; }

.ag-popup-editor {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ag-menu {
  max-height: 100%;
  overflow-y: auto;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ag-menu-column-select-wrapper {
  height: 300px;
  overflow: auto;
  width: 200px; }

.ag-menu-list {
  border-collapse: collapse;
  display: table; }

.ag-menu-option {
  display: table-row; }

.ag-menu-option-text {
  display: table-cell; }

.ag-menu-option-shortcut {
  display: table-cell; }

.ag-menu-option-icon {
  display: table-cell; }

.ag-menu-option-popup-pointer {
  display: table-cell; }

.ag-menu-separator {
  display: table-row; }

.ag-menu-separator-cell {
  display: table-cell; }

.ag-virtual-list-viewport {
  height: 100%;
  overflow-x: auto;
  width: 100%; }

.ag-virtual-list-container {
  overflow: hidden;
  position: relative; }

.ag-rich-select {
  cursor: default;
  outline: none; }

.ag-rich-select-row {
  white-space: nowrap; }

.ag-rich-select-list {
  height: 200px;
  width: 200px; }

.ag-set-filter-list {
  height: 200px;
  width: 200px; }

.ag-set-filter-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ag-virtual-list-item {
  position: absolute;
  width: 100%; }
  .ag-virtual-list-item span:empty:not(.ag-icon) {
    border-left: 1px solid transparent; }

.ag-filter-filter {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%; }

.ag-floating-filter-body input {
  height: 19px;
  margin: 0;
  width: 100%; }

.ag-floating-filter-full-body input {
  height: 19px;
  margin: 0;
  width: 100%; }

.ag-filter-select {
  margin: 4px 4px 0 4px;
  width: 110px; }

.ag-list-selection {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.ag-column-panel {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 200px; }

.ag-layout-normal .ag-column-panel {
  height: 100%; }

.ag-layout-normal .ag-column-panel-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%; }

.ag-column-container {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 50px;
  overflow: auto; }

.ag-column-select-indent {
  display: inline-block; }

.ag-ltr .ag-column-select-column {
  margin-left: 16px; }

.ag-rtl .ag-column-select-column {
  margin-right: 16px; }

.ag-column-select-column,
.ag-column-select-column-group {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .ag-column-select-column .ag-column-select-label,
  .ag-column-select-column .ag-column-select-column-group-label,
  .ag-column-select-column-group .ag-column-select-label,
  .ag-column-select-column-group .ag-column-select-column-group-label {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ag-column-select-column .ag-column-drag,
  .ag-column-select-column-group .ag-column-drag {
    min-width: 16px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }

.ag-column-select-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden; }

.ag-tool-panel .ag-column-select-panel {
  -webkit-box-flex: 4;
  -ms-flex-positive: 4;
  flex-grow: 4; }

.ag-tool-panel-horizontal-resize {
  cursor: col-resize;
  height: 100%;
  position: absolute;
  top: 0;
  width: 5px;
  z-index: 1; }

.ag-rtl .ag-tool-panel-horizontal-resize {
  float: right;
  -webkit-transform: translateX(3px);
  transform: translateX(3px); }

.ag-ltr .ag-tool-panel-horizontal-resize {
  float: left;
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px); }

.ag-menu-column-select-wrapper .ag-column-select-panel {
  height: 100%; }

.ag-hidden {
  display: none !important; }

.ag-visibility-hidden {
  visibility: hidden !important; }

.ag-faded {
  opacity: 0.3; }

.ag-width-half {
  display: inline-block;
  width: 50%; }

.ag-shake-left-to-right {
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ag-shake-left-to-right;
  animation-name: ag-shake-left-to-right; }

@-webkit-keyframes ag-shake-left-to-right {
  from {
    padding-left: 6px;
    padding-right: 2px; }
  to {
    padding-left: 2px;
    padding-right: 6px; } }

@keyframes ag-shake-left-to-right {
  from {
    padding-left: 6px;
    padding-right: 2px; }
  to {
    padding-left: 2px;
    padding-right: 6px; } }

/* icons are used outside of the grid root (in the ghost) */
.ag-icon-aggregation {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOS41IDIuNWgtNmwyIDMuNS0yIDMuNWg2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzAwMCIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-arrows {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgNmwtMS40MSAxLjQxTDE2LjE3IDlINHYyaDEyLjE3bC0xLjU4IDEuNTlMMTYgMTRsNC00eiIvPjxwYXRoIGQ9Ik00IDZsMS40MSAxLjQxTDMuODMgOUgxNnYySDMuODNsMS41OCAxLjU5TDQgMTRsLTQtNHoiLz48cGF0aCBkPSJNNiAxNmwxLjQxLTEuNDFMOSAxNi4xN1Y0aDJ2MTIuMTdsMS41OS0xLjU4TDE0IDE2bC00IDR6Ii8+PHBhdGggZD0iTTE0IDRsLTEuNDEgMS40MUwxMSAzLjgzVjE2SDlWMy44M0w3LjQxIDUuNDEgNiA0bDQtNHoiLz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-asc {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01IDNoMnY5SDV6Ii8+PHBhdGggZD0iTTguOTkzIDUuMlYzLjQ5M2gtNnY2SDQuN1Y1LjJoNC4yOTN6IiBpZD0iYiIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2EiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik01LjUgMy41aDF2OGgtMXoiLz48ZyB0cmFuc2Zvcm09InJvdGF0ZSg0NSA1Ljk5MyA2LjQ5MykiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2IiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik04LjQ5MyA0Ljd2LS43MDdoLTV2NUg0LjJWNC43aDQuMjkzeiIvPjwvZz48L2c+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-checkbox-checked-readonly {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHJlY3QgaWQ9ImEiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjEiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNhIi8+PHJlY3Qgc3Ryb2tlPSIjMDAwIiB4PSIuNSIgeT0iLjUiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgcng9IjEiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik05IDNMNiA4LjVsLTIuNS0yIi8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-checkbox-checked {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHJlY3QgaWQ9ImEiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjEiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiNGRkYiIHhsaW5rOmhyZWY9IiNhIi8+PHJlY3Qgc3Ryb2tlPSIjMDAwIiB4PSIuNSIgeT0iLjUiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgcng9IjEiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik05IDNMNiA4LjVsLTIuNS0yIi8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-checkbox-indeterminate-readonly {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHJlY3QgaWQ9ImEiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjEiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNhIi8+PHJlY3Qgc3Ryb2tlPSIjMDAwIiB4PSIuNSIgeT0iLjUiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgcng9IjEiLz48cGF0aCBmaWxsPSIjMDAwIiBkPSJNNCA1aDR2Mkg0eiIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-checkbox-indeterminate {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHJlY3QgaWQ9ImEiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjEiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiNGRkYiIHhsaW5rOmhyZWY9IiNhIi8+PHJlY3Qgc3Ryb2tlPSIjMDAwIiB4PSIuNSIgeT0iLjUiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgcng9IjEiLz48cGF0aCBmaWxsPSIjMDAwIiBkPSJNNCA1aDR2Mkg0eiIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-checkbox-unchecked-readonly {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHJlY3QgaWQ9ImEiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjEiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNhIi8+PHJlY3Qgc3Ryb2tlPSIjMDAwIiB4PSIuNSIgeT0iLjUiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgcng9IjEiLz48L2c+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-checkbox-unchecked {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHJlY3QgaWQ9ImEiIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgcng9IjEiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiNGRkYiIHhsaW5rOmhyZWY9IiNhIi8+PHJlY3Qgc3Ryb2tlPSIjMDAwIiB4PSIuNSIgeT0iLjUiIHdpZHRoPSIxMSIgaGVpZ2h0PSIxMSIgcng9IjEiLz48L2c+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-column {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxaDR2Mkgxem0wIDNoNHY3SDF6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-columns {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxaDR2Mkgxem02IDBoNHYySDd6TTEgNWg0djJIMXptNiAwaDR2Mkg3ek0xIDloNHYySDF6bTYgMGg0djJIN3oiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-contracted {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHN0cm9rZS1vcGFjaXR5PSIuNSIgc3Ryb2tlPSIjMDAwIiB4PSIxLjUiIHk9IjEuNSIgd2lkdGg9IjkiIGhlaWdodD0iOSIgcng9IjEiLz48cGF0aCBmaWxsPSIjMDAwIiBkPSJNOSA1djJIM1Y1eiIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-copy {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDAiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTQuNSA0LjVoNXY1aC01eiIvPjxwYXRoIGQ9Ik03LjUgMi41aC01djVoMnYyaDV2LTVoLTJ2LTJ6Ii8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-cut {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDAiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTMgMy4xMmMuNjY3LjA3OCAzIDEuNzQ1IDcgNS0uMzI2LjIwNC0uNjU5LjIwNC0xIDAtLjM0MS0uMjA2LTEuNjc0LTEuMjA2LTQtMyAwIC42NjYtLjY2Ny42NjYtMiAwLTItMS0xLTIuMTIgMC0yeiIvPjxwYXRoIGQ9Ik0zIDguMjY0Yy42NjctLjA4IDMtMS43NDYgNy01LS4zMjYtLjIwNS0uNjU5LS4yMDUtMSAwLS4zNDEuMjA0LTEuNjc0IDEuMjA0LTQgMyAwLS42NjctLjY2Ny0uNjY3LTIgMC0yIDEtMSAyLjExOSAwIDJ6Ii8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-desc {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01IDJoMnY5SDV6Ii8+PHBhdGggZD0iTTguOTkzIDYuMVY0LjM5M2gtNnY2SDQuN1Y2LjFoNC4yOTN6IiBpZD0iYiIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2EiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik01LjUgMi41aDF2OGgtMXoiLz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtMTM1IDUuOTkzIDcuMzkzKSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjYiIvPjxwYXRoIHN0cm9rZT0iIzAwMCIgZD0iTTguNDkzIDUuNnYtLjcwN2gtNXY1SDQuMlY1LjZoNC4yOTN6Ii8+PC9nPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-expanded {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IHN0cm9rZS1vcGFjaXR5PSIuNSIgc3Ryb2tlPSIjMDAwIiB4PSIxLjUiIHk9IjEuNSIgd2lkdGg9IjkiIGhlaWdodD0iOSIgcng9IjEiLz48cGF0aCBmaWxsPSIjMDAwIiBkPSJNNSAzaDJ2Nkg1eiIvPjxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik05IDV2MkgzVjV6Ii8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-eye-slash {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zLjAwMSAzLjkwOEwzIDRhMyAzIDAgMSAwIDUuOTk5LS4wOTJBNS4yNDggNS4yNDggMCAwIDAgNiAzYy0xLjEgMC0yLjEuMzAzLTIuOTk5LjkwOHoiIGZpbGw9IiMwMDAiLz48cGF0aCBkPSJNNCA0LjVjLjY2Ny0uMzMzIDEuNjY3LS41IDMtLjUiIHN0cm9rZT0iIzk3OTc5NyIvPjxwYXRoIGQ9Ik0xIDZjMS4zMzMtMiAzLTMgNS0zczMuNjY3IDEgNSAzQzkuNjY3IDggOCA5IDYgOVMyLjMzMyA4IDEgNnoiIHN0cm9rZT0iIzAwMCIvPjxwYXRoIGQ9Ik00LjAwNCAyLjgzNWw0Ljk5MiA2LjMzIiBzdHJva2U9IiMwMDAiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJNMy4wMDQgMi44MzVsNC45OTIgNi4zMyIgc3Ryb2tlPSIjRkZGIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-eye {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zLjAwMSAzLjkwOEwzIDRhMyAzIDAgMSAwIDUuOTk5LS4wOTJBNS4yNDggNS4yNDggMCAwIDAgNiAzYy0xLjEgMC0yLjEuMzAzLTIuOTk5LjkwOHoiIGZpbGw9IiMwMDAiLz48cGF0aCBkPSJNNCA0LjVjLjY2Ny0uMzMzIDEuNjY3LS41IDMtLjUiIHN0cm9rZT0iIzk3OTc5NyIvPjxwYXRoIGQ9Ik0xIDZjMS4zMzMtMiAzLTMgNS0zczMuNjY3IDEgNSAzQzkuNjY3IDggOCA5IDYgOVMyLjMzMyA4IDEgNnoiIHN0cm9rZT0iIzAwMCIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-filter {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAyaDEwTDcgNnY1TDUgOVY2TDEgMnptNCA0djFoMlY2SDV6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-folder-open {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDAiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTEuMzMzIDIuNUwuNSAzLjV2Ni4yMTRsMSAuNzg2aDhsMS0xdi01bC0xLTFoLTNsLTEtMXoiLz48cGF0aCBkPSJNMi41IDEwLjVMMiA5bDEtMyAyLjUtLjVoNmwtMSA1eiIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-folder {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDAiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTEuMzMzIDIuNUwuNSAzLjV2Ni4yMTRsMSAuNzg2aDhsMS0xdi01bC0xLTFoLTNsLTEtMXoiLz48cGF0aCBkPSJNNy41IDMuNWwtMiAyaC01Ii8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-group {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIHN0cm9rZT0iIzAwMCIgZD0iTTcuNSAxLjVoM3YyaC0zem0wIDRoM3YyaC0zem0wIDRoM3YyaC0zeiIvPjxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik0yIDNoMXY4SDJ6bTEgM2g0djFIM3ptMi00aDN2MUg1eiIvPjxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik0yIDEwaDV2MUgyeiIvPjxwYXRoIHN0cm9rZT0iIzAwMCIgZD0iTTEuNSAxLjVoM3YyaC0zeiIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-indeterminate {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zLjA1NiA0LjU4MWEzLjAwMSAzLjAwMSAwIDAgMCA1Ljg4OCAwQzguMDU5IDQuMTk0IDcuMDc4IDQgNiA0Yy0xLjA3OCAwLTIuMDYuMTk0LTIuOTQ0LjU4MXoiIGZpbGw9IiMwMDAiLz48cGF0aCBkPSJNNCA1LjVjLjY2Ny0uMzMzIDEuNjY3LS41IDMtLjUiIHN0cm9rZT0iIzk3OTc5NyIvPjxwYXRoIGQ9Ik0xIDZjMS4zMzMtMS4zMzMgMy0yIDUtMnMzLjY2Ny42NjcgNSAyQzkuNjY3IDcuMzMzIDggOCA2IDhzLTMuNjY3LS42NjctNS0yeiIgc3Ryb2tlPSIjMDAwIi8+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-left {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01LjUgMS41aDJ2OWgtMnoiLz48cGF0aCBkPSJNNy45OTMgNC43VjIuOTkzaC02djZIMy43VjQuN2g0LjI5M3oiIGlkPSJiIi8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoOTAgNi41IDYpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNhIi8+PHBhdGggc3Ryb2tlPSIjMDAwIiBkPSJNNiAyaDF2OEg2eiIvPjwvZz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtNDUgNC45OTMgNS45OTMpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNiIi8+PHBhdGggc3Ryb2tlPSIjMDAwIiBkPSJNNy40OTMgNC4ydi0uNzA3aC01djVIMy4yVjQuMmg0LjI5M3oiLz48L2c+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-loading {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01IDFoMnYzSDV6Ii8+PHBhdGggaWQ9ImIiIGQ9Ik01IDhoMnYzSDV6Ii8+PHBhdGggaWQ9ImMiIGQ9Ik0xIDVoM3YySDF6Ii8+PHBhdGggaWQ9ImQiIGQ9Ik04IDVoM3YySDh6Ii8+PHBhdGggaWQ9ImUiIGQ9Ik00IDBoMnYzSDR6Ii8+PHBhdGggaWQ9ImYiIGQ9Ik00IDdoMnYzSDR6Ii8+PHBhdGggaWQ9ImciIGQ9Ik0wIDRoM3YySDB6Ii8+PHBhdGggaWQ9ImgiIGQ9Ik03IDRoM3YySDd6Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjYSIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTTUuNSAxLjVoMXYyaC0xeiIvPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2IiLz48cGF0aCBzdHJva2U9IiM5Nzk3OTciIGQ9Ik01LjUgOC41aDF2MmgtMXoiLz48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNjIi8+PHBhdGggc3Ryb2tlPSIjOTc5Nzk3IiBkPSJNMS41IDUuNWgydjFoLTJ6Ii8+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjZCIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTTguNSA1LjVoMnYxaC0yeiIvPjxnIG9wYWNpdHk9Ii43MTQiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1IDQuMjkzIDYuNzA3KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjZSIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTTQuNS41aDF2MmgtMXoiLz48L2c+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNC4yOTMgNi43MDcpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNmIi8+PHBhdGggc3Ryb2tlPSIjOTc5Nzk3IiBkPSJNNC41IDcuNWgxdjJoLTF6Ii8+PC9nPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1IDQuMjkzIDYuNzA3KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjZyIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTS41IDQuNWgydjFoLTJ6Ii8+PC9nPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1IDQuMjkzIDYuNzA3KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjaCIvPjxwYXRoIHN0cm9rZT0iIzk3OTc5NyIgZD0iTTcuNSA0LjVoMnYxaC0yeiIvPjwvZz48L2c+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-menu {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAxaDEwdjJIMXptMCA0aDEwdjJIMXptMCA0aDEwdjJIMXoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-minus {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMiA1aDh2MkgyeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-none {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik01IDNoMnY2SDV6Ii8+PHBhdGggZD0iTTguMTQ2IDguMTgyVjYuNDc1aC01djVoMS43MDhWOC4xODJoMy4yOTJ6IiBpZD0iYiIvPjxwYXRoIGQ9Ik04LjUgMi45MTRWMS4yMDdoLTV2NWgxLjcwN1YyLjkxNEg4LjV6IiBpZD0iYyIvPjwvZGVmcz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2EiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik01LjUgMy41aDF2NWgtMXoiLz48ZyB0cmFuc2Zvcm09InJvdGF0ZSgtMTM1IDUuNjQ2IDguNDc1KSI+PHVzZSBmaWxsPSIjRDhEOEQ4IiB4bGluazpocmVmPSIjYiIvPjxwYXRoIHN0cm9rZT0iIzAwMCIgZD0iTTcuNjQ2IDcuNjgydi0uNzA3aC00djRoLjcwOFY3LjY4MmgzLjI5MnoiLz48L2c+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNiAzLjcwNykiPjx1c2UgZmlsbD0iI0Q4RDhEOCIgeGxpbms6aHJlZj0iI2MiLz48cGF0aCBzdHJva2U9IiMwMDAiIGQ9Ik04IDIuNDE0di0uNzA3SDR2NGguNzA3VjIuNDE0SDh6Ii8+PC9nPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-not-allowed {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDAiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBjeD0iNiIgY3k9IjYiIHI9IjQiLz48cGF0aCBkPSJNOC41IDMuNUwzLjQwMSA4LjU5OSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-paste {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDAiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTIuNSAyLjVoN3Y3aC03eiIvPjxwYXRoIGQ9Ik02LjUgMS41aC0xdjJoLTF2MWgzdi0xaC0xdi0yeiIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-pin {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik0zIDJoNnYxSDh2NGwyIDFIN2wtMSAzLTEtM0gybDItMVYzSDN6Ii8+PHBhdGggZmlsbC1vcGFjaXR5PSIuNSIgZmlsbD0iI0ZGRiIgZD0iTTUgM2gxdjRINXoiLz48cGF0aCBmaWxsLW9wYWNpdHk9Ii4yOCIgZmlsbD0iI0ZGRiIgZD0iTTQgM2gxdjNINHoiLz48L2c+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-pivot {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiMwMDAiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHJlY3QgeD0iMS41IiB5PSIxLjUiIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIHJ4PSIxIi8+PHBhdGggZD0iTTEwLjUgMy41aC05bTItMnY5IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTcuNSA2LjVsMS0xIDEgMW0tMyAxbC0xIDEgMSAxIi8+PHBhdGggZD0iTTguNSA1LjV2M2gtMyIvPjwvZz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-plus {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik01IDJoMnY4SDV6Ii8+PHBhdGggZD0iTTIgNWg4djJIMnoiLz48L2c+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-right {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik00LjUgMS41aDJ2OWgtMnoiLz48cGF0aCBkPSJNOS45OTMgNC43VjIuOTkzaC02djZINS43VjQuN2g0LjI5M3oiIGlkPSJiIi8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoOTAgNS41IDYpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNhIi8+PHBhdGggc3Ryb2tlPSIjMDAwIiBkPSJNNSAyaDF2OEg1eiIvPjwvZz48ZyB0cmFuc2Zvcm09InNjYWxlKC0xIDEpIHJvdGF0ZSgtNDUgMCAyMi44NzQpIj48dXNlIGZpbGw9IiNEOEQ4RDgiIHhsaW5rOmhyZWY9IiNiIi8+PHBhdGggc3Ryb2tlPSIjMDAwIiBkPSJNOS40OTMgNC4ydi0uNzA3aC01djVINS4yVjQuMmg0LjI5M3oiLz48L2c+PC9nPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-small-left {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMyA2bDQtNHY4eiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-small-right {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSAybDQgNC00IDR6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-small-up {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMiA3bDQtNCA0IDR6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-small-down {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMiA1aDhMNiA5eiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-tick {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMS41IDUuNWwzIDMgNi02IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZT0iIzAwMCIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-cross {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMiAxMGw4LThtMCA4TDIgMiIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiLz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-tree-open {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMiA1aDhMNiA5eiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-tree-closed {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSAybDQgNC00IDR6IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.ag-icon-tree-indeterminate {
  display: inline-block;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMiA1aDh2MkgyeiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) center no-repeat;
  background-size: 12px 12px;
  -webkit-filter: "initial";
  filter: "initial";
  height: 12px;
  width: 12px; }

.loading-filter {
  background-color: #e6e6e6;
  height: 100%;
  padding: 5px;
  position: absolute;
  top: 34px;
  width: 100%;
  z-index: 1; }

.ag-details-row {
  height: 100%;
  width: 100%; }

.ag-details-grid {
  height: 100%;
  width: 100%; }

.ag-column-select-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }

.ag-ltr .ag-toolpanel-indent-1 {
  padding-left: 10px; }

.ag-rtl .ag-toolpanel-indent-1 {
  padding-right: 10px; }

.ag-ltr .ag-row-group-indent-1 {
  padding-left: 10px; }

.ag-rtl .ag-row-group-indent-1 {
  padding-right: 10px; }

.ag-ltr .ag-toolpanel-indent-2 {
  padding-left: 20px; }

.ag-rtl .ag-toolpanel-indent-2 {
  padding-right: 20px; }

.ag-ltr .ag-row-group-indent-2 {
  padding-left: 20px; }

.ag-rtl .ag-row-group-indent-2 {
  padding-right: 20px; }

.ag-ltr .ag-toolpanel-indent-3 {
  padding-left: 30px; }

.ag-rtl .ag-toolpanel-indent-3 {
  padding-right: 30px; }

.ag-ltr .ag-row-group-indent-3 {
  padding-left: 30px; }

.ag-rtl .ag-row-group-indent-3 {
  padding-right: 30px; }

.ag-ltr .ag-toolpanel-indent-4 {
  padding-left: 40px; }

.ag-rtl .ag-toolpanel-indent-4 {
  padding-right: 40px; }

.ag-ltr .ag-row-group-indent-4 {
  padding-left: 40px; }

.ag-rtl .ag-row-group-indent-4 {
  padding-right: 40px; }

.ag-ltr .ag-toolpanel-indent-5 {
  padding-left: 50px; }

.ag-rtl .ag-toolpanel-indent-5 {
  padding-right: 50px; }

.ag-ltr .ag-row-group-indent-5 {
  padding-left: 50px; }

.ag-rtl .ag-row-group-indent-5 {
  padding-right: 50px; }

.ag-ltr .ag-toolpanel-indent-6 {
  padding-left: 60px; }

.ag-rtl .ag-toolpanel-indent-6 {
  padding-right: 60px; }

.ag-ltr .ag-row-group-indent-6 {
  padding-left: 60px; }

.ag-rtl .ag-row-group-indent-6 {
  padding-right: 60px; }

.ag-ltr .ag-toolpanel-indent-7 {
  padding-left: 70px; }

.ag-rtl .ag-toolpanel-indent-7 {
  padding-right: 70px; }

.ag-ltr .ag-row-group-indent-7 {
  padding-left: 70px; }

.ag-rtl .ag-row-group-indent-7 {
  padding-right: 70px; }

.ag-ltr .ag-toolpanel-indent-8 {
  padding-left: 80px; }

.ag-rtl .ag-toolpanel-indent-8 {
  padding-right: 80px; }

.ag-ltr .ag-row-group-indent-8 {
  padding-left: 80px; }

.ag-rtl .ag-row-group-indent-8 {
  padding-right: 80px; }

.ag-ltr .ag-toolpanel-indent-9 {
  padding-left: 90px; }

.ag-rtl .ag-toolpanel-indent-9 {
  padding-right: 90px; }

.ag-ltr .ag-row-group-indent-9 {
  padding-left: 90px; }

.ag-rtl .ag-row-group-indent-9 {
  padding-right: 90px; }

.ag-ltr .ag-toolpanel-indent-10 {
  padding-left: 100px; }

.ag-rtl .ag-toolpanel-indent-10 {
  padding-right: 100px; }

.ag-ltr .ag-row-group-indent-10 {
  padding-left: 100px; }

.ag-rtl .ag-row-group-indent-10 {
  padding-right: 100px; }

.ag-ltr .ag-toolpanel-indent-11 {
  padding-left: 110px; }

.ag-rtl .ag-toolpanel-indent-11 {
  padding-right: 110px; }

.ag-ltr .ag-row-group-indent-11 {
  padding-left: 110px; }

.ag-rtl .ag-row-group-indent-11 {
  padding-right: 110px; }

.ag-ltr .ag-toolpanel-indent-12 {
  padding-left: 120px; }

.ag-rtl .ag-toolpanel-indent-12 {
  padding-right: 120px; }

.ag-ltr .ag-row-group-indent-12 {
  padding-left: 120px; }

.ag-rtl .ag-row-group-indent-12 {
  padding-right: 120px; }

.ag-ltr .ag-toolpanel-indent-13 {
  padding-left: 130px; }

.ag-rtl .ag-toolpanel-indent-13 {
  padding-right: 130px; }

.ag-ltr .ag-row-group-indent-13 {
  padding-left: 130px; }

.ag-rtl .ag-row-group-indent-13 {
  padding-right: 130px; }

.ag-ltr .ag-toolpanel-indent-14 {
  padding-left: 140px; }

.ag-rtl .ag-toolpanel-indent-14 {
  padding-right: 140px; }

.ag-ltr .ag-row-group-indent-14 {
  padding-left: 140px; }

.ag-rtl .ag-row-group-indent-14 {
  padding-right: 140px; }

.ag-ltr .ag-toolpanel-indent-15 {
  padding-left: 150px; }

.ag-rtl .ag-toolpanel-indent-15 {
  padding-right: 150px; }

.ag-ltr .ag-row-group-indent-15 {
  padding-left: 150px; }

.ag-rtl .ag-row-group-indent-15 {
  padding-right: 150px; }

.ag-ltr .ag-toolpanel-indent-16 {
  padding-left: 160px; }

.ag-rtl .ag-toolpanel-indent-16 {
  padding-right: 160px; }

.ag-ltr .ag-row-group-indent-16 {
  padding-left: 160px; }

.ag-rtl .ag-row-group-indent-16 {
  padding-right: 160px; }

.ag-ltr .ag-toolpanel-indent-17 {
  padding-left: 170px; }

.ag-rtl .ag-toolpanel-indent-17 {
  padding-right: 170px; }

.ag-ltr .ag-row-group-indent-17 {
  padding-left: 170px; }

.ag-rtl .ag-row-group-indent-17 {
  padding-right: 170px; }

.ag-ltr .ag-toolpanel-indent-18 {
  padding-left: 180px; }

.ag-rtl .ag-toolpanel-indent-18 {
  padding-right: 180px; }

.ag-ltr .ag-row-group-indent-18 {
  padding-left: 180px; }

.ag-rtl .ag-row-group-indent-18 {
  padding-right: 180px; }

.ag-ltr .ag-toolpanel-indent-19 {
  padding-left: 190px; }

.ag-rtl .ag-toolpanel-indent-19 {
  padding-right: 190px; }

.ag-ltr .ag-row-group-indent-19 {
  padding-left: 190px; }

.ag-rtl .ag-row-group-indent-19 {
  padding-right: 190px; }

.ag-ltr .ag-toolpanel-indent-20 {
  padding-left: 200px; }

.ag-rtl .ag-toolpanel-indent-20 {
  padding-right: 200px; }

.ag-ltr .ag-row-group-indent-20 {
  padding-left: 200px; }

.ag-rtl .ag-row-group-indent-20 {
  padding-right: 200px; }

.ag-ltr .ag-toolpanel-indent-21 {
  padding-left: 210px; }

.ag-rtl .ag-toolpanel-indent-21 {
  padding-right: 210px; }

.ag-ltr .ag-row-group-indent-21 {
  padding-left: 210px; }

.ag-rtl .ag-row-group-indent-21 {
  padding-right: 210px; }

.ag-ltr .ag-toolpanel-indent-22 {
  padding-left: 220px; }

.ag-rtl .ag-toolpanel-indent-22 {
  padding-right: 220px; }

.ag-ltr .ag-row-group-indent-22 {
  padding-left: 220px; }

.ag-rtl .ag-row-group-indent-22 {
  padding-right: 220px; }

.ag-ltr .ag-toolpanel-indent-23 {
  padding-left: 230px; }

.ag-rtl .ag-toolpanel-indent-23 {
  padding-right: 230px; }

.ag-ltr .ag-row-group-indent-23 {
  padding-left: 230px; }

.ag-rtl .ag-row-group-indent-23 {
  padding-right: 230px; }

.ag-ltr .ag-toolpanel-indent-24 {
  padding-left: 240px; }

.ag-rtl .ag-toolpanel-indent-24 {
  padding-right: 240px; }

.ag-ltr .ag-row-group-indent-24 {
  padding-left: 240px; }

.ag-rtl .ag-row-group-indent-24 {
  padding-right: 240px; }

.ag-ltr .ag-toolpanel-indent-25 {
  padding-left: 250px; }

.ag-rtl .ag-toolpanel-indent-25 {
  padding-right: 250px; }

.ag-ltr .ag-row-group-indent-25 {
  padding-left: 250px; }

.ag-rtl .ag-row-group-indent-25 {
  padding-right: 250px; }

.ag-ltr .ag-toolpanel-indent-26 {
  padding-left: 260px; }

.ag-rtl .ag-toolpanel-indent-26 {
  padding-right: 260px; }

.ag-ltr .ag-row-group-indent-26 {
  padding-left: 260px; }

.ag-rtl .ag-row-group-indent-26 {
  padding-right: 260px; }

.ag-ltr .ag-toolpanel-indent-27 {
  padding-left: 270px; }

.ag-rtl .ag-toolpanel-indent-27 {
  padding-right: 270px; }

.ag-ltr .ag-row-group-indent-27 {
  padding-left: 270px; }

.ag-rtl .ag-row-group-indent-27 {
  padding-right: 270px; }

.ag-ltr .ag-toolpanel-indent-28 {
  padding-left: 280px; }

.ag-rtl .ag-toolpanel-indent-28 {
  padding-right: 280px; }

.ag-ltr .ag-row-group-indent-28 {
  padding-left: 280px; }

.ag-rtl .ag-row-group-indent-28 {
  padding-right: 280px; }

.ag-ltr .ag-toolpanel-indent-29 {
  padding-left: 290px; }

.ag-rtl .ag-toolpanel-indent-29 {
  padding-right: 290px; }

.ag-ltr .ag-row-group-indent-29 {
  padding-left: 290px; }

.ag-rtl .ag-row-group-indent-29 {
  padding-right: 290px; }

.ag-ltr .ag-toolpanel-indent-30 {
  padding-left: 300px; }

.ag-rtl .ag-toolpanel-indent-30 {
  padding-right: 300px; }

.ag-ltr .ag-row-group-indent-30 {
  padding-left: 300px; }

.ag-rtl .ag-row-group-indent-30 {
  padding-right: 300px; }

.ag-ltr .ag-toolpanel-indent-31 {
  padding-left: 310px; }

.ag-rtl .ag-toolpanel-indent-31 {
  padding-right: 310px; }

.ag-ltr .ag-row-group-indent-31 {
  padding-left: 310px; }

.ag-rtl .ag-row-group-indent-31 {
  padding-right: 310px; }

.ag-ltr .ag-toolpanel-indent-32 {
  padding-left: 320px; }

.ag-rtl .ag-toolpanel-indent-32 {
  padding-right: 320px; }

.ag-ltr .ag-row-group-indent-32 {
  padding-left: 320px; }

.ag-rtl .ag-row-group-indent-32 {
  padding-right: 320px; }

.ag-ltr .ag-toolpanel-indent-33 {
  padding-left: 330px; }

.ag-rtl .ag-toolpanel-indent-33 {
  padding-right: 330px; }

.ag-ltr .ag-row-group-indent-33 {
  padding-left: 330px; }

.ag-rtl .ag-row-group-indent-33 {
  padding-right: 330px; }

.ag-ltr .ag-toolpanel-indent-34 {
  padding-left: 340px; }

.ag-rtl .ag-toolpanel-indent-34 {
  padding-right: 340px; }

.ag-ltr .ag-row-group-indent-34 {
  padding-left: 340px; }

.ag-rtl .ag-row-group-indent-34 {
  padding-right: 340px; }

.ag-ltr .ag-toolpanel-indent-35 {
  padding-left: 350px; }

.ag-rtl .ag-toolpanel-indent-35 {
  padding-right: 350px; }

.ag-ltr .ag-row-group-indent-35 {
  padding-left: 350px; }

.ag-rtl .ag-row-group-indent-35 {
  padding-right: 350px; }

.ag-ltr .ag-toolpanel-indent-36 {
  padding-left: 360px; }

.ag-rtl .ag-toolpanel-indent-36 {
  padding-right: 360px; }

.ag-ltr .ag-row-group-indent-36 {
  padding-left: 360px; }

.ag-rtl .ag-row-group-indent-36 {
  padding-right: 360px; }

.ag-ltr .ag-toolpanel-indent-37 {
  padding-left: 370px; }

.ag-rtl .ag-toolpanel-indent-37 {
  padding-right: 370px; }

.ag-ltr .ag-row-group-indent-37 {
  padding-left: 370px; }

.ag-rtl .ag-row-group-indent-37 {
  padding-right: 370px; }

.ag-ltr .ag-toolpanel-indent-38 {
  padding-left: 380px; }

.ag-rtl .ag-toolpanel-indent-38 {
  padding-right: 380px; }

.ag-ltr .ag-row-group-indent-38 {
  padding-left: 380px; }

.ag-rtl .ag-row-group-indent-38 {
  padding-right: 380px; }

.ag-ltr .ag-toolpanel-indent-39 {
  padding-left: 390px; }

.ag-rtl .ag-toolpanel-indent-39 {
  padding-right: 390px; }

.ag-ltr .ag-row-group-indent-39 {
  padding-left: 390px; }

.ag-rtl .ag-row-group-indent-39 {
  padding-right: 390px; }

.ag-ltr .ag-toolpanel-indent-40 {
  padding-left: 400px; }

.ag-rtl .ag-toolpanel-indent-40 {
  padding-right: 400px; }

.ag-ltr .ag-row-group-indent-40 {
  padding-left: 400px; }

.ag-rtl .ag-row-group-indent-40 {
  padding-right: 400px; }

.ag-ltr .ag-toolpanel-indent-41 {
  padding-left: 410px; }

.ag-rtl .ag-toolpanel-indent-41 {
  padding-right: 410px; }

.ag-ltr .ag-row-group-indent-41 {
  padding-left: 410px; }

.ag-rtl .ag-row-group-indent-41 {
  padding-right: 410px; }

.ag-ltr .ag-toolpanel-indent-42 {
  padding-left: 420px; }

.ag-rtl .ag-toolpanel-indent-42 {
  padding-right: 420px; }

.ag-ltr .ag-row-group-indent-42 {
  padding-left: 420px; }

.ag-rtl .ag-row-group-indent-42 {
  padding-right: 420px; }

.ag-ltr .ag-toolpanel-indent-43 {
  padding-left: 430px; }

.ag-rtl .ag-toolpanel-indent-43 {
  padding-right: 430px; }

.ag-ltr .ag-row-group-indent-43 {
  padding-left: 430px; }

.ag-rtl .ag-row-group-indent-43 {
  padding-right: 430px; }

.ag-ltr .ag-toolpanel-indent-44 {
  padding-left: 440px; }

.ag-rtl .ag-toolpanel-indent-44 {
  padding-right: 440px; }

.ag-ltr .ag-row-group-indent-44 {
  padding-left: 440px; }

.ag-rtl .ag-row-group-indent-44 {
  padding-right: 440px; }

.ag-ltr .ag-toolpanel-indent-45 {
  padding-left: 450px; }

.ag-rtl .ag-toolpanel-indent-45 {
  padding-right: 450px; }

.ag-ltr .ag-row-group-indent-45 {
  padding-left: 450px; }

.ag-rtl .ag-row-group-indent-45 {
  padding-right: 450px; }

.ag-ltr .ag-toolpanel-indent-46 {
  padding-left: 460px; }

.ag-rtl .ag-toolpanel-indent-46 {
  padding-right: 460px; }

.ag-ltr .ag-row-group-indent-46 {
  padding-left: 460px; }

.ag-rtl .ag-row-group-indent-46 {
  padding-right: 460px; }

.ag-ltr .ag-toolpanel-indent-47 {
  padding-left: 470px; }

.ag-rtl .ag-toolpanel-indent-47 {
  padding-right: 470px; }

.ag-ltr .ag-row-group-indent-47 {
  padding-left: 470px; }

.ag-rtl .ag-row-group-indent-47 {
  padding-right: 470px; }

.ag-ltr .ag-toolpanel-indent-48 {
  padding-left: 480px; }

.ag-rtl .ag-toolpanel-indent-48 {
  padding-right: 480px; }

.ag-ltr .ag-row-group-indent-48 {
  padding-left: 480px; }

.ag-rtl .ag-row-group-indent-48 {
  padding-right: 480px; }

.ag-ltr .ag-toolpanel-indent-49 {
  padding-left: 490px; }

.ag-rtl .ag-toolpanel-indent-49 {
  padding-right: 490px; }

.ag-ltr .ag-row-group-indent-49 {
  padding-left: 490px; }

.ag-rtl .ag-row-group-indent-49 {
  padding-right: 490px; }

.ag-tool-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  height: 100%; }
  .ag-tool-panel .ag-side-buttons {
    width: 20px; }
    .ag-tool-panel .ag-side-buttons button {
      display: block;
      -webkit-transform: rotate(90deg) translateY(-20px);
      transform: rotate(90deg) translateY(-20px);
      -webkit-transform-origin: left top 0;
      transform-origin: left top 0;
      white-space: nowrap;
      outline: none; }
  .ag-tool-panel .panel-container {
    width: 180px; }
  .ag-tool-panel.full-width .panel-container {
    width: 200px; }

.ag-rtl .ag-tool-panel .ag-side-buttons button {
  -webkit-transform: rotate(-90deg) translatex(20px);
  transform: rotate(-90deg) translatex(20px);
  -webkit-transform-origin: right bottom 0;
  transform-origin: right bottom 0; }
